import { styled, css, keyframes } from "styled-components";
import { Link } from "react-router-dom";

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loader = styled.div`
  color: #1c4c5b
  box-sizing: border-box;
  display: inline-block;
  width: 40px;
  height: 40px;

  &:after {
    box-sizing: border-box;
    content: " ";
    display: block;
    width: 30px;
    height: 30px;
    margin: 5px 5px 5px 10px;
    border-radius: 50%;
    border: 3px solid currentColor;
    border-color: currentColor transparent currentColor transparent;
    animation: ${rotate} 1.2s linear infinite;
  }
}
`;

export const Button = styled.button<{ $type: "big" | "medium" | "blue" }>`
  background: #efe800;
  color: black;
  font-size: 40px;
  font-weight: 700;
  border-radius: 60px;
  padding: 32px 60px;
  cursor: pointer;
  outline: none;
  border: none;

  ${(props) =>
    props.$type === "big" &&
    css`
      font-size: 40px;
      padding: 32px 60px;
    `}

  ${(props) =>
    props.$type === "medium" &&
    css`
      font-size: 24px;
      padding: 24px 28px;
    `}

  ${(props) =>
    props.$type === "blue" &&
    css`
      background: #2949e5;
      color: white;
      font-size: 24px;
      padding: 16px 24px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    `}

  &:disabled {
    cursor: not-allowed;
    background: #7b7b87;
  }
`;

export const ButtonLink = styled(Link)<{ $type: "big" | "medium" }>`
  background: #efe800;
  color: black;
  font-weight: 700;
  border-radius: 60px;
  display: inline-block;
  text-decoration: none;
  flex-grow: 0;

  ${(props) =>
    props.$type === "big" &&
    css`
      font-size: 40px;
      padding: 32px 60px;
    `}

  ${(props) =>
    props.$type === "medium" &&
    css`
      font-size: 24px;
      padding: 24px 28px;
    `}
`;

export const Text = styled.p<{ size: number }>`
  font-size: ${(props) => props.size}px;
  margin: 0;

  & > a {
    color: inherit;
  }
`;

export const Main = styled.main`
  margin: 0 auto;
  max-width: 1020px;
  padding: 0 50px;
`;

export const Section = styled.div<{ $align?: "center" }>`
  display: flex;
  justify-content: space-between;
  padding: 50px 0;
  max-width: 1020px;

  ${(props) =>
    props.$align === "center" &&
    css`
      align-items: center;
    `}
`;

export const Image = styled.img`
  max-width: 100%;
  border-radius: 12px;
`;

export const Box = styled.div<{ width?: number }>`
  background: #000934;
  border-radius: 12px;
  width: ${(props) => (props.width ? props.width + "px" : "100%")};
  padding: 20px;
  box-sizing: border-box;
`;

const UL = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const Grade = styled.span<{ $type: "good" | "bad" }>`
  font-weight: bold;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: ${(props) => (props.$type === "good" ? "#44B62D" : "#FF6C40")};
  margin-right: 20px;
`;

export const GradedList = ({
  title,
  list,
}: {
  title: string;
  list: { grade: number; label: string; info: string; tip: string }[];
}): JSX.Element => {
  return (
    <div>
      <h3 style={{ textAlign: "center", textTransform: "uppercase" }}>
        {title}
      </h3>
      <UL>
        {list.map((item, i) => (
          <li key={i} style={{ margin: "20px 0" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Grade $type={item.grade > 3 ? "good" : "bad"}>
                {item.grade}
              </Grade>
              <h4 style={{ margin: "0 10px 0 0", fontSize: "24px" }}>
                {item.label}
              </h4>
            </div>
            <div style={{ paddingLeft: "70px", lineHeight: "140%" }}>
              <Text size={16}>{item.info}</Text>
              <br />
              <Text size={16}>{item.tip}</Text>
            </div>
          </li>
        ))}
      </UL>
    </div>
  );
};

const getColor = (useColors: boolean, item: string) => {
  if (!useColors) return "white";
  if (item === "Framing: Subject is well-framed.") return "#44B62D";
  if (item === "Lighting: No significant issues detected.") return "#44B62D";

  return "#FF6C40";
};

export const TitleList = ({
  title,
  list,
  useColors = false,
}: {
  title?: string;
  list: string[];
  useColors?: boolean;
}): JSX.Element => {
  return (
    <div>
      {title && (
        <h3
          style={{
            textAlign: "center",
            textTransform: "uppercase",
            fontSize: "28px",
            marginBottom: "5px",
          }}
        >
          {title}
        </h3>
      )}
      <ul>
        {list.map((item, i) => (
          <li key={i}>
            <h4
              style={{
                marginRight: "10px",
                fontSize: "24px",
                margin: 5,
                color: getColor(useColors, item),
              }}
            >
              {item}
            </h4>
          </li>
        ))}
      </ul>
    </div>
  );
};
