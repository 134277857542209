import styled from "styled-components";
import { Text } from "./elements";

const BAD_COLOR = "#FF6C40";
const GOOD_COLOR = "#44B62D";

const Container = styled.div`
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: Arial, sans-serif;
  margin: 0 auto;
  flex-direction: column;
`;

const getGrade = (score: number) => {
  if (score >= 4.5) return "A";
  if (score >= 4) return "B";
  if (score >= 3) return "C";
  if (score >= 2) return "D";
  return "F";
};

const CircularProgress = styled.div<{ score: number }>`
  position: relative;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background: conic-gradient(
    ${(props) => (props.score > 3 ? GOOD_COLOR : BAD_COLOR)}
      ${(props) => (props.score / 5) * 360}deg,
    #e0e0e0 0deg
  );
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  &::before {
    content: "";
    position: absolute;
    width: 70%;
    height: 70%;
    background-color: #0e1b38;
    border-radius: 50%;
  }

  &::after {
    content: "${(props) => getGrade(props.score)}";
    position: absolute;
    font-size: 48px;
    color: white;
  }
`;

const ScoreText = styled.div`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 5px;
`;

const SubText = styled.div`
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
`;

const Big = styled.span`
  font-size: 72px;
`;

export const Score = ({
  score,
  overview,
}: {
  score: number;
  overview: string;
}) => {
  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "400px",
        }}
      >
        <CircularProgress score={score} />
        <div>
          <ScoreText>
            <Big>{score}</Big> out of <Big>5</Big>
          </ScoreText>
          <SubText>YOUR SCORE</SubText>
        </div>
      </div>
      <div style={{ width: "400px", marginTop: "10px", fontFamily: "'GT Walsheim', sans-serif" }}>
        <Text size={24}>{overview}</Text>
      </div>
    </Container>
  );
};
