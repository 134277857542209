import { Text, Section } from "./elements";
import wistiaLogo from "./wistia.png";

export const Footer = () => {
  return (
    <footer>
      <Section
        style={{ padding: "50px 100px", display: "block", textAlign: "center" }}
      >
        <Text size={12}>
          A VIDEO TOOL FROM YOUR FRIENDS AT{" "}
          <a href="https://wistia.com">
            <img src={wistiaLogo} alt="wistia" style={{ height: "11px" }} />
          </a>
        </Text>
      </Section>
    </footer>
  );
};
