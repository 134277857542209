import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Capture } from "./Capture";
import { Result } from "./Result";
import { Home } from "./Home";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/capture",
    element: <Capture />,
  },
  {
    path: "/grade/:id",
    element: <Result />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
