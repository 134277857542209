import styled from "styled-components";
import logo from "./logo.png";
import {Link} from 'react-router-dom';

const HeaderEl = styled.h1`
  font-size: 0;
  text-align: center;
  margin: 50px 0 0;
`;

const Logo = styled.img`
  width: 320px;
`;

export const Header = () => (
  <HeaderEl>
    Grade your setup
    <Link to="/"><Logo src={logo} alt="logo" /></Link>
  </HeaderEl>
);

