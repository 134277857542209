import { styled } from "styled-components";
import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Button, Main, Text, Section, Image, Box, GradedList } from "./elements";
import { HOST } from "./host";
import { Header } from "./Header";
import { Score } from "./Score";
import { Footer } from "./Footer";

const ResponsiveMain = styled(Main)`
  padding: 0 20px;

  @media (min-width: 768px) {
    padding: 0 50px;
  }
`;

const ResponsiveSection = styled(Section)`
  padding: 30px 0;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    padding: 30px 0;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const ResponsiveBox = styled(Box)`
  width: 100%;
  max-width: 576px;
  margin-top: 20px;

  @media (min-width: 768px) {
    margin-top: 0;
  }
`;

const ResponsiveText = styled(Text)`
  font-size: 20px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 24px;
    margin-bottom: 0;
  }
`;

const TopSection = styled(ResponsiveSection)`
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  max-width: 420px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 20px;
  }
`;

const ScoreContainer = styled(ResponsiveBox)`
  @media (min-width: 768px) {
    flex: 1;
  }
`;

const ShareSection = styled(ResponsiveSection)`
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }
`;

const ShareText = styled(ResponsiveText)`
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 20px;
  }
`;

const ShareButton = styled(Button)`
  @media (max-width: 767px) {
    margin-top: 0px;
  }
`;

const FullWidthSection = styled(ResponsiveSection)`
  width: 100%;
`;

type ResponseGroup = {
  score: number;
  description: string;
  tip: string;
};

type Response = {
  overall: ResponseGroup;
  lighting: ResponseGroup;
  framing: ResponseGroup;
  "picture-quality": ResponseGroup;
  background: ResponseGroup;
};

export const Result = () => {
  let { id } = useParams();
  const location = useLocation();
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState<Response | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    const fetchResult = async () => {
      try {
        const resp = await fetch(`${HOST}/api/grade/${id}`);
        const body = await resp.json();
        setImageSrc(body.image);
        console.log(JSON.parse(body.content));
        setResponse(JSON.parse(body.content));
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        setError("An error occurred, check the console");
      }
    };
    fetchResult();
  }, [id]);

  const handleCopyURL = () => {
    const currentURL = window.location.href;
    navigator.clipboard.writeText(currentURL).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
    });
  };

  console.log(response);

  return (
    <ResponsiveMain>
      <Header />
      {response && imageSrc && (
        <>
          <TopSection>
            <ImageContainer>
              <Image src={imageSrc} alt="captured" style={{ width: '100%', height: 'auto', borderRadius: '12px' }} />
            </ImageContainer>
            <ScoreContainer>
              <Score
                score={response.overall.score}
                overview={response.overall.description}
              />
            </ScoreContainer>
          </TopSection>
          
          <ShareSection>
            <ShareText size={24}>
              Share your score with others!
            </ShareText>
            <ShareButton onClick={handleCopyURL} $type="medium">
              {isCopied ? "Copied!" : "Copy Share URL"}
            </ShareButton>
          </ShareSection>
          
          <FullWidthSection>
            <Box style={{ width: '100%' }}>
              <GradedList
                title="MORE ABOUT YOUR SETUP"
                list={[
                  {
                    grade: response.lighting.score,
                    label: "Lighting",
                    info: response.lighting.description,
                    tip: response.lighting.tip,
                  },
                  {
                    grade: response.framing.score,
                    label: "Framing",
                    info: response.framing.description,
                    tip: response.framing.tip,
                  },
                  {
                    grade: response["picture-quality"].score,
                    label: "Picture Quality",
                    info: response["picture-quality"].description,
                    tip: response["picture-quality"].tip,
                  },
                  {
                    grade: response.background.score,
                    label: "Background",
                    info: response.background.description,
                    tip: response.background.tip,
                  },
                ]}
              />
            </Box>
          </FullWidthSection>
        </>
      )}
      <Footer />
    </ResponsiveMain>
  );
};