import { useEffect, useCallback, useState } from "react";
import { Camera } from "@mediapipe/camera_utils";
import { FaceMesh, Results } from "@mediapipe/face_mesh";

/* luminance stuff */
const faceSides = {
  left: {
    landmarks: [10, 332, 284, 389, 361, 378, 152],
    color: "rgba(0, 255, 0, 0.0)",
  },
  right: {
    landmarks: [10, 103, 54, 162, 132, 150, 152],
    color: "rgba(255, 0, 0, 0.0)",
  },
};

const getLandmarkRegion = (
  landmarks: any,
  indices: number[],
  videoElement: HTMLVideoElement
) => {
  return indices.map((index) => ({
    x: landmarks[index].x * videoElement.width,
    y: landmarks[index].y * videoElement.height,
  }));
};

const calculateLuminanceMetrics = (
  imageData: Uint8ClampedArray,
  region: any[],
  videoElement: HTMLVideoElement
) => {
  const luminanceValues: number[] = [];
  const histogram = new Array(256).fill(0);

  const minX = Math.min(...region.map((p) => p.x));
  const maxX = Math.max(...region.map((p) => p.x));
  const minY = Math.min(...region.map((p) => p.y));
  const maxY = Math.max(...region.map((p) => p.y));

  for (let y = Math.floor(minY); y <= Math.ceil(maxY); y++) {
    for (let x = Math.floor(minX); x <= Math.ceil(maxX); x++) {
      if (isPointInPolygon({ x, y }, region)) {
        const i = (y * videoElement.width + x) * 4;
        const r = imageData[i];
        const g = imageData[i + 1];
        const b = imageData[i + 2];
        const luminance = Math.floor(0.299 * r + 0.587 * g + 0.114 * b);
        luminanceValues.push(luminance);
        histogram[luminance]++;
      }
    }
  }

  luminanceValues.sort((a, b) => a - b);
  const median = luminanceValues[Math.floor(luminanceValues.length / 2)] / 255;

  return {
    median: median,
    histogram: histogram,
  };
};

const sampleBackgroundLuminanceAndHistogram = (
  imageData: Uint8ClampedArray,
  landmarks: any,
  videoElement: HTMLVideoElement
) => {
  const width = videoElement.width;
  const height = videoElement.height;
  const sampleSize = 1000;
  let totalLuminance = 0;
  const histogram = new Array(256).fill(0);

  const facePoints = [
    ...faceSides.left.landmarks,
    ...faceSides.right.landmarks,
  ].map((index) => landmarks[index]);
  const minX = Math.min(...facePoints.map((p) => p.x)) * width;
  const maxX = Math.max(...facePoints.map((p) => p.x)) * width;
  // const minY = Math.min(...facePoints.map((p) => p.y)) * height;
  // const maxY = Math.max(...facePoints.map((p) => p.y)) * height;

  const faceWidth = maxX - minX;

  for (let i = 0; i < sampleSize; i++) {
    let x, y;
    if (i % 2 === 0) {
      x = Math.floor(Math.random() * (minX - faceWidth * 0.5));
    } else {
      x = Math.floor(
        Math.random() * (width - maxX - faceWidth * 0.5) +
          maxX +
          faceWidth * 0.5
      );
    }
    y = Math.floor(Math.random() * height);

    const index = (y * width + x) * 4;
    const r = imageData[index];
    const g = imageData[index + 1];
    const b = imageData[index + 2];
    const luminance = Math.floor(0.299 * r + 0.587 * g + 0.114 * b);
    totalLuminance += luminance;
    histogram[luminance]++;
  }

  return {
    backgroundLuminance: totalLuminance / sampleSize / 255,
    backgroundHistogram: histogram,
  };
};

type Histogram = number[];

const calculateEMD = (hist1: Histogram, hist2: Histogram): number => {
  const cdf1 = getCumulativeHistogram(hist1);
  const cdf2 = getCumulativeHistogram(hist2);
  let distance = 0;

  for (let i = 0; i < 256; i++) {
    distance += Math.abs(cdf1[i] - cdf2[i]);
  }

  return distance / 256;
};

const getCumulativeHistogram = (histogram: Histogram): number[] => {
  const total = histogram.reduce((sum, count) => sum + count, 0);
  const cumulativeHist = new Array(256).fill(0);
  let cumSum = 0;

  for (let i = 0; i < 256; i++) {
    cumSum += histogram[i];
    cumulativeHist[i] = cumSum / total;
  }

  return cumulativeHist;
};

const getImageData = (video: HTMLVideoElement): ImageData => {
  // Create an off-screen canvas
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  if (!context) {
    throw new Error("Failed to get 2D context");
  }

  // Set the canvas dimensions to match the video element
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;

  // Draw the current frame from the video onto the canvas
  context.drawImage(video, 0, 0, canvas.width, canvas.height);

  // Get the image data from the canvas
  const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

  return imageData;
};

const isPointInPolygon = (point: any, polygon: any[]) => {
  let isInside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i].x,
      yi = polygon[i].y;
    const xj = polygon[j].x,
      yj = polygon[j].y;
    /* eslint-disable */
    const intersect =
      yi > point.y !== yj > point.y &&
      point.x < ((xj - xi) * (point.y - yi)) / (yj - yi) + xi;
    /* eslint-enable */
    if (intersect) isInside = !isInside;
  }
  return isInside;
};

const analyzeLuminance = (video: HTMLVideoElement, landmarks: any) => {
  const imageData = getImageData(video);
  const data = imageData.data;

  const luminanceValues: any = {};

  for (const [side, region] of Object.entries(faceSides)) {
    const points = getLandmarkRegion(landmarks, region.landmarks, video);
    luminanceValues[side] = calculateLuminanceMetrics(data, points, video);
  }

  const { backgroundLuminance, backgroundHistogram } =
    sampleBackgroundLuminanceAndHistogram(data, landmarks, video);
  const emdValue = calculateEMD(
    luminanceValues.left.histogram,
    luminanceValues.right.histogram
  );
  const backLightRatio =
    backgroundLuminance /
    ((luminanceValues.left.median + luminanceValues.right.median) / 2);

  let backlightStatus = "none";
  if (backLightRatio > 1.5) {
    backlightStatus = "definite";
  } else if (backLightRatio > 1.2) {
    backlightStatus = "possible";
  }

  const stuff = {
    left: luminanceValues.left,
    right: luminanceValues.right,
    emdValue: emdValue.toFixed(4),
    backgroundLuminance: backgroundLuminance.toFixed(2),
    backlightStatus: backlightStatus,
    backlightRatio: backLightRatio.toFixed(2),
    faceHistogram: luminanceValues.left.histogram.map(
      (v: number, i: number) => v + luminanceValues.right.histogram[i]
    ),
    backgroundHistogram: backgroundHistogram,
  };

  return stuff;
};

const generateLLMSummary = (analysis: any) => {
  if (!analysis.left || !analysis.left.median) {
    return {
      summary: "No face detected in the webcam image.",
      issues: ["No face detected."],
    };
  }

  let summary = ``;

  let issues = [];
  if (parseFloat(analysis.emdValue) > 0.2) {
    // Threshold for uneven lighting, adjust as needed
    issues.push("Uneven lighting");
    if (analysis.left.median < analysis.right.median) {
      issues.push("Left side underlit");
    } else {
      issues.push("Right side underlit");
    }
  }
  if (analysis.backlightStatus === "definite") {
    issues.push("Is backlit");
  } else if (analysis.backlightStatus === "possible") {
    issues.push("Might be backlit");
  }
  if ((analysis.left.median + analysis.right.median) / 2 < 0.3) {
    issues.push("Too dark");
  } else if ((analysis.left.median + analysis.right.median) / 2 > 0.7) {
    issues.push("Too bright");
  }

  if (issues.length > 0) {
    summary += `Issues: ${issues.join(", ")}\n`;
  } else {
    issues.push("No significant issues detected.");
    summary += `No significant issues detected.\n`;
  }

  summary += `Left side median luminance: ${analysis.left.median.toFixed(
    2
  )} (0-1 scale)\n`;
  summary += `Right side median luminance: ${analysis.right.median.toFixed(
    2
  )} (0-1 scale)\n`;
  summary += `EMD between left and right: ${analysis.emdValue} (0-1 scale, higher means more difference)\n`;
  summary += `Background luminance: ${analysis.backgroundLuminance}\n`;
  summary += `Backlight ratio: ${analysis.backlightRatio}\n`;

  return { summary, issues };
};
/* end luminance stuff */

const minFaceRatio = 0.05;
const maxFaceRatio = 0.2;
const minHeadroom = 0.2;
const maxHeadroom = 0.4;
const centerThreshold = 0.1;

const analyzeFraming = (landmarks: any, videoElement: HTMLVideoElement) => {
  if (!landmarks || landmarks.length === 0) {
    return { text: "No face detected", color: "black" };
  }

  const width = videoElement.width;
  const height = videoElement.height;

  // Calculate face bounding box
  let minX = 1,
    minY = 1,
    maxX = 0,
    maxY = 0;
  for (const landmark of landmarks) {
    minX = Math.min(minX, landmark.x);
    minY = Math.min(minY, landmark.y);
    maxX = Math.max(maxX, landmark.x);
    maxY = Math.max(maxY, landmark.y);
  }

  const faceWidth = (maxX - minX) * width;
  const faceHeight = (maxY - minY) * height;
  const faceArea = faceWidth * faceHeight;
  const imageArea = width * height;
  const faceRatio = faceArea / imageArea;

  const centerX = 0.5;
  const faceCenter = {
    x: (minX + maxX) / 2,
    y: (minY + maxY) / 2,
  };

  const distanceFromCenter = Math.sqrt(Math.pow(faceCenter.x - centerX, 2));

  const maxAllowedDistance = centerThreshold;
  const isCentered = distanceFromCenter <= maxAllowedDistance;

  const headroom = minY;

  let framingQuality = "Poor";
  let color = "red";

  if (
    faceRatio >= minFaceRatio &&
    faceRatio <= maxFaceRatio &&
    isCentered &&
    headroom >= minHeadroom &&
    headroom <= maxHeadroom
  ) {
    framingQuality = "Optimal";
    color = "purple";
  } else if (
    faceRatio >= minFaceRatio * 0.9 &&
    faceRatio <= maxFaceRatio * 1.1 &&
    isCentered &&
    headroom >= minHeadroom * 0.9 &&
    headroom <= maxHeadroom * 1.1
  ) {
    framingQuality = "Good";
    color = "green";
  } else if (
    faceRatio >= minFaceRatio * 0.8 &&
    faceRatio <= maxFaceRatio * 1.2 &&
    headroom >= minHeadroom * 0.8 &&
    headroom <= maxHeadroom * 1.2
  ) {
    framingQuality = "Acceptable";
    color = "orange";
  }

  let llmAnalysis = [];

  if (faceRatio < minFaceRatio) {
    llmAnalysis.push("Subject is too far from the camera.");
  } else if (faceRatio > maxFaceRatio) {
    llmAnalysis.push("Subject is too close to the camera.");
  }

  if (!isCentered) {
    llmAnalysis.push("Subject is off-center.");
  }

  if (headroom < minHeadroom) {
    llmAnalysis.push("Not enough space above subject's head.");
  } else if (headroom > maxHeadroom) {
    llmAnalysis.push("Too much space above subject's head.");
  }

  if (llmAnalysis.length === 0) {
    llmAnalysis.push("Subject is well-framed.");
  }

  const llmText = llmAnalysis.join(" ");

  return {
    text: `
Framing Quality: ${framingQuality}
Face Size: ${(faceRatio * 100).toFixed(2)}% of frame
Centered: ${isCentered ? "Yes" : "No"}
Headroom: ${(headroom * 100).toFixed(2)}% of frame height
                `,
    color: color,
    llmText: llmText,
  };
};

export const useAnalyzer = (videoElement: HTMLVideoElement | null) => {
  const [analysis, setAnalysis] = useState<{
    text: string;
    color: string;
    llmText?: string;
  } | null>(null);
  const [luminanceInfo, setLuminanceInfo] = useState<{
    llmSummary: {
      summary: string;
      issues: string[];
    };
    leftHistogram: number[];
    rightHistogram: number[];
    backgroundHistogram: number[];
  }>({
    llmSummary: {
      summary: "Analyzing...",
      issues: [],
    },
    leftHistogram: [],
    rightHistogram: [],
    backgroundHistogram: [],
  });

  const updateLuminanceInfo = useCallback((analysis: any) => {
    setLuminanceInfo({
      llmSummary: generateLLMSummary(analysis),
      leftHistogram: analysis.left?.histogram || [],
      rightHistogram: analysis.right?.histogram || [],
      backgroundHistogram: analysis.backgroundHistogram || [],
    });
  }, []);

  const onResults = useCallback(
    (results: Results) => {
      if (!videoElement) return;
      if (results.multiFaceLandmarks && results.multiFaceLandmarks.length > 0) {
        const framingAnalysis = analyzeFraming(
          results.multiFaceLandmarks[0],
          videoElement
        );
        setAnalysis(framingAnalysis);

        const landmarks = results.multiFaceLandmarks[0];
        const luminanceAnalysis = analyzeLuminance(videoElement, landmarks);
        updateLuminanceInfo(luminanceAnalysis);
      } else {
        setAnalysis({
          text: "No face detected",
          color: "black",
          llmText: "No face detected",
        });
        updateLuminanceInfo({
          left: "-",
          right: "-",
          absoluteDifference: "-",
          percentDifference: "-",
          isBacklit: false,
          backLightRatio: "-",
        });
      }
    },
    [videoElement, updateLuminanceInfo]
  );

  useEffect(() => {
    const faceMesh = new FaceMesh({
      locateFile: (file) =>
        `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`,
    });

    faceMesh.setOptions({
      maxNumFaces: 1,
      refineLandmarks: true,
      minDetectionConfidence: 0.5,
      minTrackingConfidence: 0.5,
    });

    faceMesh.onResults(onResults);

    if (!videoElement) return;

    const camera = new Camera(videoElement, {
      onFrame: async () => {
        await faceMesh.send({ image: videoElement });
      },
      width: videoElement.width,
      height: videoElement.height,
    });

    camera.start();
  }, [videoElement, onResults]);

  return {
    framingAnalysis: analysis,
    luminanceAnalysis: luminanceInfo,
  };
};
