import React from "react";
import { styled } from "styled-components";
import grades from "./grades.png";
import { ButtonLink, Text, Main, Section, Image } from "./elements";
import { Footer } from "./Footer";
import { Header } from "./Header";

const ResponsiveSection = styled(Section)`
  padding: 50px 20px;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (min-width: 768px) {
    padding: 50px 100px;
  }
`;

const ResponsiveText = styled(Text)`
  line-height: 140%;
  margin-bottom: 40px;

  @media (max-width: 767px) {
    font-size: ${props => props.size === 36 ? '24px' : props.size + 'px'};
  }
`;

const ResponsiveButtonLink = styled(ButtonLink)`
  @media (max-width: 767px) {
    font-size: 24px;
    padding: 24px 28px;
    max-width: 250px;
  }
`;

const GradesImage = styled(Image)`
  max-width: 100%;
  height: auto;
`;

export const Home = () => {
  return (
    <Main>
      <Header />
      <ResponsiveSection>
        <ResponsiveText size={36}>
          From the video experts at <a href="https://wistia.com" target="_blank" rel="noopener noreferrer">Wistia</a>, 
          Webcam Grader delivers pro tips to perfect your on-camera appearance.
        </ResponsiveText>
        <ResponsiveButtonLink to="/capture" $type="big">
          Start Grading
        </ResponsiveButtonLink>
      </ResponsiveSection>
      <Section>
        <GradesImage src={grades} alt="grades" />
      </Section>
      <ResponsiveSection>
        <ResponsiveText size={36}>
          Say goodbye to dull video calls and webinars.
          <br />
          Get expert advice tailored to your webcam setup and shine on camera like a pro!
        </ResponsiveText>
      </ResponsiveSection>
      <Footer />
    </Main>
  );
};