import React, { useState, useEffect } from "react";
import Select, { StylesConfig } from "react-select";

export interface DeviceOption {
  value: string;
  label: string;
}

const customStyles: StylesConfig<DeviceOption, false> = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'white',
    borderColor: '#4a90e2',
    boxShadow: state.isFocused ? '0 0 0 1px #4a90e2' : 'none',
    '&:hover': {
      borderColor: '#4a90e2',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#4a90e2' : state.isFocused ? '#e8f0fe' : 'white',
    color: state.isSelected ? 'white' : '#333',
    '&:active': {
      backgroundColor: '#4a90e2',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#333',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#4a90e2',
    '&:hover': {
      color: '#3a7cbd',
    },
  }),
  menu: (provided) => ({
    ...provided,
    borderColor: '#4a90e2',
  }),
};

export const Devices: React.FC<{
  onDeviceChange: (device: DeviceOption | null) => void;
}> = ({ onDeviceChange }) => {
  const [videoInputs, setVideoInputs] = useState<DeviceOption[]>([]);
  const [selectedInput, setSelectedInput] = useState<DeviceOption | null>(null);

  useEffect(() => {
    const getVideoInputs = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoInputDevices = devices
          .filter((device) => device.kind === "videoinput")
          .map((device) => ({
            value: device.deviceId,
            label: device.label || `Camera ${device.deviceId}`,
          }));
        setVideoInputs(videoInputDevices);
        if (videoInputDevices.length > 0) {
          setSelectedInput(videoInputDevices[0]);
        }
      } catch (err) {
        console.error("Error fetching video input devices:", err);
      }
    };
    getVideoInputs();
  }, []);

  const handleChange = (selectedOption: DeviceOption | null) => {
    setSelectedInput(selectedOption);
    onDeviceChange(selectedOption);
  };

  return (
    <div>
      <Select
        value={selectedInput}
        onChange={handleChange}
        options={videoInputs}
        styles={customStyles}
        placeholder="Select a video input"
      />
    </div>
  );
};