import Webcam from "react-webcam";
import { styled } from "styled-components";
import { useCallback, useMemo, useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { HOST } from "./host";
import {
  Main,
  Section,
  Button,
  Box,
  Text,
  TitleList,
  Loader,
} from "./elements";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Devices, DeviceOption } from "./Devices";
import { useAnalyzer } from "./useAnalyzer";

const getFramingText = (
  framingAnalysis: string | null,
  luminanceAnalysis: string | null
) => {
  return `
You are Stephen Petto, the friendly, expert video producer from Wistia. You are tasked with rating and improving someone's webcam setup. I want you to grade the following dimensions of the image from their webcam setup from 1 to 5. Here are the dimensions and some notes:

lighting: the person’s face should be evenly lit and they should be well separated from the background
framing: the person should be centered and not cut off. the camera should be at eye-level and not looking up or down at the person
picture-quality: look for light blooms or blurriness which suggest a dirty or low quality webcam
background: the background should be clean and visually appealing with intentional production set design

We’ve also already done some analysis of some of these dimensions to help guide your ratings. Here is what we know:

framing: ${framingAnalysis}
lighting: ${luminanceAnalysis}

Your output should consist only of a JSON object with each dimension, the score you give them, a short description of the rating you gave, and a concrete tip to improve. Also include the overall score, a description, and the single most relevant tip to improve.

In your ratings and descriptions:
- Make sure you use the analysis provided to guide your ratings
- Say at least one nice thing in your overall summary
- Be positive, encouraging, and conversational in tone
- Remark on specific elements in the image. (e.g. “The plant behind you is a nice touch.”).
- Address the subject as “you” when referring to them in the description.
- If there are no significant issues with the lighting and the face is well lit, give it a 5.
- If the component scores are all 4s and 5s, give a 4.5 or 5 for overall score.
- If a dimension is great, give it a 5. Also, be honest about the setup and don’t be afraid to give low grades (like a 1 or 2) when some aspect of the setup really isn’t hitting the mark.

The output structure should look like this:

{
  lighting: { score: 4, description: "...", tip: "..." },
  framing: { score: 5, description: "...", tip: "..." },
  picture-quality: { score: 5, description: "...", tip: "..." },
  background: { score: 4, description: "...", tip: "..." },
  overall: { score: 4, description: "...", tip: "..." },
}
`;
};

const Feedback = ({
  framingAnalysis,
  luminanceAnalysis,
}: ReturnType<typeof useAnalyzer>) => {
  return (
    <TitleList
      useColors={true}
      title="Feedback"
      list={[
        `Framing: ${framingAnalysis?.llmText ?? "Unavailable"}`,
        `Lighting: ${luminanceAnalysis?.llmSummary?.issues.join(", ") ?? "Unavailable"}`,
      ]}
    />
  );
};

const ResponsiveMain = styled(Main)`
  padding: 0 20px;

  @media (min-width: 768px) {
    padding: 0 50px;
  }
`;

const ResponsiveSection = styled(Section)`
  flex-direction: column;
  align-items: center;

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const ResponsiveBox = styled(Box)`
  width: 100%;
  max-width: 500px;
  margin-bottom: 20px;

  @media (min-width: 1024px) {
    width: 48%;
    margin-bottom: 0;
  }
`;

const ResponsiveWebcam = styled(Webcam)`
  width: 100%;
  height: auto;
  max-width: 460px;
  max-height: 345px;
`;

const DebugSection = styled.div`
  margin-top: 20px;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 12px;
  background-color: #000;
`;

const DebugSubSection = styled.div`
  margin-bottom: 20px;
`;

const DebugLabel = styled.span`
  font-weight: bold;
  color: #fff;
`;

const HistogramCanvas = styled.canvas`
  width: 100%;
  height: 200px;
  margin-bottom: 20px;
`;

interface HistogramPlotProps {
  data: number[];
  title: string;
}

const HistogramPlot: React.FC<HistogramPlotProps> = ({ data, title }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      
      if (ctx) {
        const width = canvas.width;
        const height = canvas.height;

        // Clear the canvas
        ctx.clearRect(0, 0, width, height);

        // Find the maximum value in the data
        const maxValue = Math.max(...data);

        // Draw the histogram
        ctx.fillStyle = '#8884d8';
        const barWidth = width / data.length;
        data.forEach((value, index) => {
          const barHeight = (value / maxValue) * height;
          ctx.fillRect(index * barWidth, height - barHeight, barWidth, barHeight);
        });

        // Draw axes
        ctx.strokeStyle = '#000';
        ctx.beginPath();
        ctx.moveTo(0, height);
        ctx.lineTo(width, height);
        ctx.stroke();

        // Draw title
        ctx.fillStyle = '#000';
        ctx.font = '14px Arial';
        ctx.fillText(title, 10, 20);
      }
    }
  }, [data, title]);

  return <HistogramCanvas ref={canvasRef} />;
};

const formatDebugInfo = (info: any): string => {
  if (!info) return 'No data available';
  
  const formatSection = (section: any): string => {
    return Object.entries(section)
      .map(([key, value]) => {
        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
          return `${key}:\n${formatSection(value).split('\n').map(line => `  ${line}`).join('\n')}`;
        }
        if (key !== 'leftHistogram' && key !== 'rightHistogram' && key !== 'backgroundHistogram') {
          return `${key}: ${value}`;
        }
        return null;
      })
      .filter(Boolean)
      .join('\n');
  };

  return formatSection(info);
};

export const Capture = () => {
  const location = useLocation();
  const showDebug = location.search.includes('?debug');
  const webcamRef = useRef<Webcam>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [responseText, setResponseText] = useState<string | null>(null);
  const navigate = useNavigate();
  const [prompt, setPrompt] = useState(getFramingText(null, null));
  const [device, setDevice] = useState<DeviceOption | null>(null);
  const [videoElement, setVideoElement] = useState<HTMLVideoElement | null>(
    null
  );

  const { framingAnalysis, luminanceAnalysis } = useAnalyzer(videoElement);
  // @ts-ignore
  window.webcam = webcamRef.current;

  const capture = useCallback(() => {
    if (!webcamRef.current) return;
    const imageSrc = webcamRef.current.getScreenshot();
    setImageSrc(imageSrc);
  }, [webcamRef]);

  const grade = useCallback(async () => {
    if (!imageSrc) return;

    setIsLoading(true);
    const formData = new FormData();
    formData.append("image", imageSrc);
    formData.append("prompt", prompt);
    try {
      const resp = await fetch(`${HOST}/api/grade`, {
        method: "POST",
        body: formData,
      });

      const body = await resp.json();
      navigate(`/grade/${body.id}`);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setResponseText("An error occurred, check the console");
    }
  }, [imageSrc, navigate, prompt]);

  const constraints = useMemo(() => {
    if (!device)
      return {
        video: {
          width: { ideal: 4096 },
          height: { ideal: 2160 },
          facingMode: "environment",
        },
      };

    return {
      deviceId: device.value,
      video: {
        width: { ideal: 4096 },
        height: { ideal: 2160 },
        facingMode: "environment",
      },
    };
  }, [device]);

  useEffect(() => {
    if (imageSrc && !isLoading) {
      grade();
    }
  }, [imageSrc, isLoading, grade]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (videoElement && videoElement === webcamRef.current?.video) {
        clearInterval(interval);
      }
      if (webcamRef.current && webcamRef.current?.video !== videoElement) {
        setVideoElement(webcamRef.current.video);
        clearInterval(interval);
      }
    }, 500);

    return () => clearInterval(interval);
  }, [videoElement]);

  useEffect(() => {
    setPrompt(
      getFramingText(
        framingAnalysis?.llmText ?? null,
        luminanceAnalysis?.llmSummary?.summary
      )
    );
  }, [framingAnalysis, luminanceAnalysis]);

  return (
    <ResponsiveMain>
      <Header />
      {!responseText && (
        <div>
          <ResponsiveSection style={{minHeight: '600px'}}>
            <ResponsiveBox style={{ background: "white" }}>
              {imageSrc ? (
                <img src={imageSrc} alt="webcam" style={{ width: '100%', height: 'auto' }} />
              ) : (
                <ResponsiveWebcam
                  ref={webcamRef}
                  mirrored={true}
                  videoConstraints={constraints}
                  width={460}
                  height={345}
                />
              )}
              <br />
              <Devices onDeviceChange={setDevice} />
              <br />
              <Button onClick={capture} $type="blue" disabled={isLoading}>
                {isLoading ? (
                  <>
                    Consulting the Oracle
                    <Loader />
                  </>
                ) : (
                  <>Grade My Setup</>
                )}
              </Button>
              <Text
                size={10}
                style={{
                  color: "#7B7B87",
                  textAlign: "center",
                  padding: "20px 0 0",
                }}
              >
                By clicking "Grade My Setup" you agree to our{" "}
                <a href="https://wistia.com/terms">Terms of Service</a> and{" "}
                <a href="https://wistia.com/privacy">Privacy Policy</a>.
              </Text>
            </ResponsiveBox>
            <ResponsiveBox>
              <Text
                size={24}
                style={{ textAlign: "center", padding: "30px 40px 10px" }}
              >
                We're going to assess your video and give you actionable
                insights to improve your setup!
              </Text>
              <TitleList
                list={[
                  "Lighting",
                  "Framing",
                  "Picture Quality",
                  "Webcam Position",
                  "Background",
                ]}
              />
              <Feedback
                framingAnalysis={framingAnalysis}
                luminanceAnalysis={luminanceAnalysis}
              />

              {/* Debug Section */}
              {showDebug && (
                <DebugSection>
                  <DebugSubSection>
                    <DebugLabel>Framing Analysis:</DebugLabel>
                    <pre>{formatDebugInfo(framingAnalysis)}</pre>
                  </DebugSubSection>
                  <DebugSubSection>
                    <DebugLabel>Luminance Analysis:</DebugLabel>
                    <pre>{formatDebugInfo(luminanceAnalysis)}</pre>
                  </DebugSubSection>
                  {luminanceAnalysis && (
                    <>
                      <HistogramPlot 
                        data={luminanceAnalysis.leftHistogram} 
                        title="Left Face Histogram" 
                      />
                      <HistogramPlot 
                        data={luminanceAnalysis.rightHistogram} 
                        title="Right Face Histogram" 
                      />
                      <HistogramPlot 
                        data={luminanceAnalysis.backgroundHistogram} 
                        title="Background Histogram" 
                      />
                    </>
                  )}
                </DebugSection>
              )}
            </ResponsiveBox>
          </ResponsiveSection>
        </div>
      )}
      <Footer />
    </ResponsiveMain>
  );
};
